import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useTemplateCreate() {
  const { error } = useNotification();
  const { mutate: templateCreate, loading, onDone, onError } = useMutation(TEMPLATE_CREATE);

  onError((err) => {
    console.error('useTemplateCreate', err);
    error();
    throw err;
  });

  return { templateCreate, loading, onDone, onError };
}

const TEMPLATE_CREATE = gql`
  mutation templateCreate($data: AutomationTemplateInput) {
    templateCreate(data: $data) {
      id
    }
  }
`;
