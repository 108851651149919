import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useTemplateDelete() {
  const { error } = useNotification();
  const { mutate: templateDelete, loading, onDone, onError } = useMutation(TEMPLATE_DELETE);

  onError((err) => {
    console.error('useTemplateDelete', err);
    error();
  });

  return { templateDelete, loading, onDone, onError };
}

const TEMPLATE_DELETE = gql`
  mutation templateDelete($id: ID!) {
    templateDelete(id: $id)
  }
`;
