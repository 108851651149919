import { gql } from '@apollo/client/core';
import { apolloClient } from '@/plugins/apollo-client';

export async function documentAutomation(documentId) {
  const response = await apolloClient.query({ query: TEMPLATES_QUERY, variables: { documentId } });
  return response.data.documentAutomation;
}

const TEMPLATES_QUERY = gql`
  query documentAutomation($documentId: ID!) {
    documentAutomation(documentId: $documentId) {
      document {
        id
        documentNumber
        issueDate
        items {
          sku
          name
          quantity
          packageQuantity
          quantityInPackage
          price
          discountRate
          discountAmount
          totalDiscount
          totalAmount
          productId
        }
        rounding
        discountRate
        discountAmount
        netAmount
        taxableAmount
        nonTaxableAmount
        taxRate
        taxAmount
        totalAmount
      }
      metadata {
        populate
        error
      }
    }
  }
`;
