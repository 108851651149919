import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useStructureTemplate(variables) {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(STRUCTURE_TEMPLATE_QUERY, variables);

  const result2 = useResult(result, { nodes: [], totalCount: 0 });
  const structureTemplates = computed(() => result2.value.nodes);
  const totalCount = computed(() => result2.value.totalCount);

  onError((err) => {
    console.error('useStructureTemplate', err);
    error();
  });

  return {
    structureTemplates,
    totalCount,
    loading,
    onError,
  };
}

const STRUCTURE_TEMPLATE_QUERY = gql`
  query structureTemplate($first: Int, $after: String, $fromDate: String, $toDate: String, $supplierId: String) {
    structureTemplates(first: $first, after: $after, fromDate: $fromDate, toDate: $toDate, supplierId: $supplierId) {
      totalCount
      nodes {
        id
        structureToken
        supplierId
        documentType
        count
        name
        isManual
        isArchived
        updatedAt
        updatedBy
        fields {
          key
        }
        supplier {
          name
        }
        document {
          id
          type: newType
          documentNumber
        }
      }
    }
  }
`;
