import { Rect } from '@/modules/core/components/DrawingOverlay';

export const rectToFieldCoordinates = (rect) => {
  const dividersCount = rect.xDividers.length + rect.yDividers.length;
  if (dividersCount === 0)
    return {
      valueCoordinates: { topLeft: { x: rect.left, y: rect.top }, bottomRight: { x: rect.right, y: rect.bottom } },
    };
  if (dividersCount === 1) {
    const [colIndex, rowIndex] = rect.fills[0];
    if (colIndex === 0)
      return {
        labelCoordinates: {
          topLeft: { x: rect.left, y: rect.top },
          bottomRight: { x: rect.xDividers[0], y: rect.bottom },
        },
        valueCoordinates: {
          topLeft: { x: rect.xDividers[0], y: rect.top },
          bottomRight: { x: rect.right, y: rect.bottom },
        },
      };
    if (colIndex === 1)
      return {
        labelCoordinates: {
          topLeft: { x: rect.xDividers[0], y: rect.top },
          bottomRight: { x: rect.right, y: rect.bottom },
        },
        valueCoordinates: {
          topLeft: { x: rect.left, y: rect.top },
          bottomRight: { x: rect.xDividers[0], y: rect.bottom },
        },
      };
    if (rowIndex === 0)
      return {
        labelCoordinates: {
          topLeft: { x: rect.left, y: rect.top },
          bottomRight: { x: rect.right, y: rect.yDividers[0] },
        },
        valueCoordinates: {
          topLeft: { x: rect.left, y: rect.yDividers[0] },
          bottomRight: { x: rect.right, y: rect.bottom },
        },
      };
    if (rowIndex === 1)
      return {
        labelCoordinates: {
          topLeft: { x: rect.left, y: rect.yDividers[0] },
          bottomRight: { x: rect.right, y: rect.bottom },
        },
        valueCoordinates: {
          topLeft: { x: rect.left, y: rect.top },
          bottomRight: { x: rect.right, y: rect.yDividers[0] },
        },
      };
  }
  const fields = [
    {
      labelCoordinates: {
        topLeft: { x: rect.left, y: rect.top },
        bottomRight: { x: rect.xDividers[0], y: rect.yDividers[0] },
      },
      valueCoordinates: {
        topLeft: { x: rect.left, y: rect.yDividers[0] },
        bottomRight: { x: rect.xDividers[0], y: rect.bottom },
      },
    },
    ...rect.xDividers.map((x, index) => ({
      labelCoordinates: {
        topLeft: { x, y: rect.top },
        bottomRight: { x: rect.xDividers[index + 1] || rect.right, y: rect.yDividers[0] },
      },
      valueCoordinates: {
        topLeft: { x, y: rect.yDividers[0] },
        bottomRight: { x: rect.xDividers[index + 1] || rect.right, y: rect.bottom },
      },
    })),
  ];
  return {
    valueCoordinates: { topLeft: { x: rect.left, y: rect.top }, bottomRight: { x: rect.right, y: rect.bottom } },
    fields,
  };
};

export const fieldCoordinatesToRect = (field) => {
  if (field.labelCoordinates) {
    // regular key value field
    const fieldCords = [field.labelCoordinates, field.valueCoordinates];
    const rect = Rect.create({
      topLeft: {
        x: Math.min(...fieldCords.map(({ topLeft }) => topLeft.x)),
        y: Math.min(...fieldCords.map(({ topLeft }) => topLeft.y)),
      },
      bottomRight: {
        x: Math.max(...fieldCords.map(({ bottomRight }) => bottomRight.x)),
        y: Math.max(...fieldCords.map(({ bottomRight }) => bottomRight.y)),
      },
    });
    if (field.labelCoordinates.topLeft.x === field.valueCoordinates.bottomRight.x) {
      rect.xDividers.push(field.labelCoordinates.topLeft.x);
      rect.fills.push([1]);
    }
    if (field.labelCoordinates.bottomRight.x === field.valueCoordinates.topLeft.x) {
      rect.xDividers.push(field.labelCoordinates.bottomRight.x);
      rect.fills.push([0]);
    }
    if (field.labelCoordinates.topLeft.y === field.valueCoordinates.bottomRight.y) {
      rect.yDividers.push(field.labelCoordinates.topLeft.y);
      rect.fills.push([null, 1]);
    }
    if (field.labelCoordinates.bottomRight.y === field.valueCoordinates.topLeft.y) {
      rect.yDividers.push(field.labelCoordinates.bottomRight.y);
      rect.fills.push([null, 0]);
    }
    return rect;
  }
  const rect = Rect.create({ ...field.valueCoordinates });
  if (!field.fields?.length) return rect; // only value field
  // table field
  rect.yDividers.push(field.fields[0].labelCoordinates.bottomRight.y);
  rect.xDividers.push(
    ...field.fields.map(({ labelCoordinates }) => labelCoordinates.topLeft.x).filter((_, index) => index)
  );
  rect.fills.push([null, 0]);
  return rect;
};
