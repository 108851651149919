import { pick } from 'ramda';
import { KEY_VALUE_DIRECTION, FIELD_TYPE } from './constants';

const getKeyValueDirection = (field) => {
  if (!field.labelCoordinates) return;
  if (field.labelCoordinates.topLeft.x === field.valueCoordinates.bottomRight.x) return KEY_VALUE_DIRECTION.RTL;
  if (field.labelCoordinates.bottomRight.x === field.valueCoordinates.topLeft.x) return KEY_VALUE_DIRECTION.LTR;
  if (field.labelCoordinates.topLeft.y === field.valueCoordinates.bottomRight.y) return KEY_VALUE_DIRECTION.BTT;
  if (field.labelCoordinates.bottomRight.y === field.valueCoordinates.topLeft.y) return KEY_VALUE_DIRECTION.TTB;
};

export const getFormFromField = (field) => ({
  ...pick(['key', 'label', 'valueType', 'tableType'], field),
  keyValueDirection: getKeyValueDirection(field),
  fields: field.fields?.map((subField) => pick(['key', 'label', 'valueType'], subField)),
});

const getCenter = (coordinates, axis) =>
  coordinates.topLeft[axis] + (coordinates.bottomRight[axis] - coordinates.topLeft[axis]) / 2;

const getCoordinates = (field, keyValueDirection) => {
  const { labelCoordinates, valueCoordinates } = field;
  const currentDirection = getKeyValueDirection(field);
  if (keyValueDirection === currentDirection) return { labelCoordinates, valueCoordinates }; // nothing changed
  if ((currentDirection ?? '').split('').reverse().join('') === keyValueDirection)
    // just replace between coordinates
    return {
      labelCoordinates: valueCoordinates,
      valueCoordinates: labelCoordinates,
    };
  const fieldCoordinates = labelCoordinates
    ? {
        topLeft: {
          x: Math.min(labelCoordinates.topLeft.x, valueCoordinates.topLeft.x),
          y: Math.min(labelCoordinates.topLeft.y, valueCoordinates.topLeft.y),
        },
        bottomRight: {
          x: Math.max(labelCoordinates.bottomRight.x, valueCoordinates.bottomRight.x),
          y: Math.max(labelCoordinates.bottomRight.y, valueCoordinates.bottomRight.y),
        },
      }
    : valueCoordinates;

  if (!keyValueDirection) return { valueCoordinates: fieldCoordinates };
  return {
    labelCoordinates: {
      topLeft: {
        x:
          keyValueDirection === KEY_VALUE_DIRECTION.RTL ? getCenter(fieldCoordinates, 'x') : fieldCoordinates.topLeft.x,
        y:
          keyValueDirection === KEY_VALUE_DIRECTION.BTT ? getCenter(fieldCoordinates, 'y') : fieldCoordinates.topLeft.y,
      },
      bottomRight: {
        x:
          keyValueDirection === KEY_VALUE_DIRECTION.LTR
            ? getCenter(fieldCoordinates, 'x')
            : fieldCoordinates.bottomRight.x,
        y:
          keyValueDirection === KEY_VALUE_DIRECTION.TTB
            ? getCenter(fieldCoordinates, 'y')
            : fieldCoordinates.bottomRight.y,
      },
    },
    valueCoordinates: {
      topLeft: {
        x:
          keyValueDirection === KEY_VALUE_DIRECTION.LTR ? getCenter(valueCoordinates, 'x') : valueCoordinates.topLeft.x,
        y:
          keyValueDirection === KEY_VALUE_DIRECTION.TTB ? getCenter(valueCoordinates, 'y') : valueCoordinates.topLeft.y,
      },
      bottomRight: {
        x:
          keyValueDirection === KEY_VALUE_DIRECTION.RTL
            ? getCenter(valueCoordinates, 'x')
            : valueCoordinates.bottomRight.x,
        y:
          keyValueDirection === KEY_VALUE_DIRECTION.BTT
            ? getCenter(valueCoordinates, 'y')
            : valueCoordinates.bottomRight.y,
      },
    },
  };
};

const getColumnCoordinates = (formFields, currentFields, index, outerCoords) => {
  if (formFields.length === currentFields?.length)
    return pick(['labelCoordinates', 'valueCoordinates'], currentFields[index]);

  const columnWidth = (outerCoords.bottomRight.x - outerCoords.topLeft.x) / formFields.length;
  const left = outerCoords.topLeft.x + columnWidth * index;
  const right = index === formFields.length - 1 ? outerCoords.bottomRight.x : left + columnWidth;

  const headerDefaultHeight = (outerCoords.bottomRight.y - outerCoords.topLeft.y) / 3;
  const headerDivider = currentFields?.length
    ? currentFields[0].labelCoordinates.bottomRight.y
    : outerCoords.topLeft.y + headerDefaultHeight;

  return {
    labelCoordinates: {
      topLeft: { x: left, y: outerCoords.topLeft.y },
      bottomRight: { x: right, y: headerDivider },
    },
    valueCoordinates: {
      topLeft: { x: left, y: headerDivider },
      bottomRight: { x: right, y: outerCoords.bottomRight.y },
    },
  };
};

export const updateFieldFromForm = (field, form) => {
  const { key, label, valueType, keyValueDirection, tableType } = form;
  const updatedField = {
    key,
    label,
    valueType,
    ...getCoordinates(field, keyValueDirection),
  };
  if (valueType === FIELD_TYPE.ARRAY) {
    updatedField.tableType = tableType;
    updatedField.fields = form.fields.map(({ key, label, valueType }, index) => ({
      key,
      label,
      valueType,
      ...getColumnCoordinates(form.fields, field.fields, index, updatedField.valueCoordinates),
    }));
  }
  return updatedField;
};
