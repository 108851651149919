import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

export function useTemplateUpdate(disableErrorMessage = false) {
  const { error } = useNotification();
  const { mutate, loading, onDone, onError } = useMutation(TEMPLATE_UPDATE);

  onError((err) => {
    if (!disableErrorMessage) {
      console.error('useTemplateUpdate', err);
      error();
    }
    throw err;
  });

  return {
    templateUpdate: (variables) => mutate(JSON.parse(JSON.stringify(variables), omitTypename)),
    loading,
    onDone,
    onError,
  };
}

const TEMPLATE_UPDATE = gql`
  mutation templateUpdate($id: ID!, $data: AutomationTemplateUpdateInput) {
    templateUpdate(id: $id, data: $data) {
      id
      name
      documentId
      isArchived
      fields {
        key
        label
        valueType
        labelCoordinates {
          topLeft {
            x
            y
          }
          bottomRight {
            x
            y
          }
        }
        valueCoordinates {
          topLeft {
            x
            y
          }
          bottomRight {
            x
            y
          }
        }
        fields {
          key
          label
          valueType
          labelCoordinates {
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
          }
          valueCoordinates {
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
          }
        }
      }
    }
  }
`;
