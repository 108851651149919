export const KEY_VALUE_DIRECTION = {
  BTT: 'btt',
  TTB: 'ttb',
  RTL: 'rtl',
  LTR: 'ltr',
};

export const FIELD_TYPE = {
  STRING: 'String',
  NUMBER: 'Number',
  DATE: 'Date',
  ARRAY: 'Array',
  MONEY: 'Money',
  OBJECT: 'Object',
};

export const STATE_MODE = {
  AUTOMATION: 'automation',
  TEMPLATE_EDITING: 'templateEditing',
  NO_TEMPLATES: 'noTemplates',
};
