import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function useTemplates(variables, options) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(TEMPLATES_QUERY, variables, options);

  const result2 = useResult(result, { nodes: [], totalCount: 0 });
  const templates = computed(() => result2.value.nodes);
  const totalCount = computed(() => result2.value.totalCount);

  onError((err) => {
    console.error('useTemplates', err);
    error();
  });

  return {
    templates,
    totalCount,
    refetch,
    loading,
    onError,
  };
}

const TEMPLATES_QUERY = gql`
  query templates(
    $supplierId: ID!
    $documentType: DocumentType!
    $ids: [ID]
    $signedUrl: Boolean!
    $isArchived: Boolean
  ) {
    templates(supplierId: $supplierId, documentType: $documentType, ids: $ids, isArchived: $isArchived) {
      totalCount
      nodes {
        id
        name
        isManual
        locale
        isArchived
        supplierId
        document(signedUrl: $signedUrl) @include(if: $signedUrl) {
          filePathUrl
        }
        documentId
        documentType
        structureToken
        fields {
          key
          label
          valueType
          tableType
          labelCoordinates {
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
          }
          valueCoordinates {
            topLeft {
              x
              y
            }
            bottomRight {
              x
              y
            }
          }
          fields {
            key
            label
            valueType
            labelCoordinates {
              topLeft {
                x
                y
              }
              bottomRight {
                x
                y
              }
            }
            valueCoordinates {
              topLeft {
                x
                y
              }
              bottomRight {
                x
                y
              }
            }
          }
        }
        structureParams {
          documentNumber
          allocationNumber
          issueDate
          orderReference
          deliveryDate
          netAmount
          taxableAmount
          nonTaxableAmount
          taxRate
          taxAmount
          openingBalance
          amountDue
          taxAmount
          totalAmount
          discountRate
          discountAmount
          paymentDueDate
          paidAmount
          paymentDate
          paymentMethod
          referencesFromDate
          referencesToDate
          rounding {
            debit
          }
          items {
            name
            sku
            gtin
            price {
              includingTax
            }
            netPrice
            quantity
            quantityInPackage
            packageQuantity
            discountRate
            discountAmount
            totalDiscount
            totalTax
            totalDeposits
            totalPackages
            totalAmount {
              includingTax
              includingDiscount
            }
            reference
          }
          generalCharges {
            name
            amount
          }
          references {
            documentNumber
            issueDate
            paymentDueDate
            netAmount
            debitAmount
            creditAmount
            totalAmount
            balance {
              debit
            }
            items {
              name
              sku
              gtin
              price {
                includingTax
              }
              netPrice
              quantity
              quantityInPackage
              packageQuantity
              discountRate
              discountAmount
              totalDiscount
              totalTax
              totalDeposits
              totalPackages
              totalAmount {
                includingTax
                includingDiscount
              }
              reference
            }
          }
        }
      }
    }
  }
`;
